import React from 'react'

export default function ContactForm() {
    return (
        <div className="py-10 px-4 sm:pl-10 sm:pr-6 lg:pr-8 xl:pl-12 lg:col-span-2 xl:py-12">
            <h3 className="text-3xl font-extrabold text-gray-900">Send us a message</h3>
            <form name="contact v1" data-netlify="true" netlify-honeypot="trap-field" action="/success/" method="POST" className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <input type="hidden" name="form-name" value="contact v1" />
                <p className="hidden">
                    <label>Don’t fill this out if you’re human: <input name="trap-field" /></label>
                </p>
                <div>
                    <label htmlFor="first_name" className="block text-sm font-medium text-gray-900">
                        First name
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="first_name"
                            required="true"
                            id="first_name"
                            autoComplete="given-name"
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border-gray-300 rounded-sm"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="last_name" className="block text-sm font-medium text-gray-900">
                        Last name
                    </label>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="last_name"
                            required="true"
                            id="last_name"
                            autoComplete="family-name"
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border-gray-300 rounded-sm"
                        />
                    </div>
                </div>
                <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                        Email
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            required="true"
                            type="email"
                            autoComplete="email"
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border-gray-300 rounded-sm"
                        />
                    </div>
                </div>
                <div>
                    <div className="flex justify-between">
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                            Phone
                        </label>
                        <span id="phone-optional" className="text-sm text-gray-500">
                            Optional
                        </span>
                    </div>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border-gray-300 rounded-sm"
                            aria-describedby="phone-optional"
                        />
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <div className="flex justify-between">
                        <label htmlFor="channel" className="block text-sm font-medium text-gray-900">
                            How did you hear about us?
                        </label>
                        <span id="channel-optional" className="text-sm text-gray-500">
                            Optional
                        </span>
                    </div>
                    <div className="mt-1">
                        <input
                            type="text"
                            name="subject"
                            id="subject"
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border-gray-300 rounded-sm"
                        />
                    </div>
                </div>
                <div className="sm:col-span-2">
                    <div className="flex justify-between">
                        <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                            Message
                        </label>
                        <span id="message-max" className="text-sm text-gray-500">
                            Max. 500 characters
                        </span>
                    </div>
                    <div className="mt-1">
                        <textarea
                            id="message"
                            name="message"
                            required="true"
                            rows={4}
                            className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-transparent focus:border-gray-400 border border-gray-300 rounded-sm"
                            aria-describedby="message-max"
                            defaultValue={''}
                        />
                    </div>
                </div>
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                    <button
                        type="submit"
                        className="mt-2 w-full inline-flex rounded-sm items-center justify-center px-6 py-3 border border-transparent shadow-sm text-base font-medium text-white bg-dark-500 hover:bg-dark-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent sm:w-auto"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    )
}
