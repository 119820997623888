import * as React from "react"
import { MailIcon, OfficeBuildingIcon } from '@heroicons/react/solid'

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactForm from "../components/contactForm"

const ContactPage = () => {
  return (
    <Layout>
      <Seo title='Sara Fashionwear'/>
      <div className="mt-6">
        <main className="overflow-hidden">
          {/* Contact section */}
          <section className="relative lg:pt-12" aria-labelledby="contactHeading">
            <div className="absolute w-full h-1/2 " aria-hidden="true" />
            <div className="max-w-7xl mx-auto">
              <div className="relative">
                <h2 id="contactHeading" className="sr-only">
                  Contact us
                </h2>
                <div className="grid grid-cols-1 lg:grid-cols-3">
                  {/* Contact information */}
                  <div className="relative overflow-hidden py-10 px-4 sm:px-6 lg:px-8 xl:py-12 xl:pr-12 lg:border-r ">
                    <h3 className="text-3xl font-extrabold text-gray-900">Contact details</h3>
                    <p className="mt-6 text-base text-gray-900 max-w-3xl">
                      Reach out to us if you would like to discuss your ideas with us or see samples of our work.
                    </p>
                    <dl className="mt-8 space-y-6">
                      <dt>
                        <span className="sr-only">Email</span>
                      </dt>
                      <dd className="flex text-base text-gray-900">
                        <MailIcon className="flex-shrink-0 w-6 h-6 text-dark-500" aria-hidden="true" />
                        <span className="ml-3">info@sarafashionwear.com</span>
                      </dd>
                      <dt>
                        <span className="sr-only">LinkedIn</span>
                      </dt>
                      <a href='https://www.linkedin.com/in/sara-abu/' target='_blank' rel="noreferrer"><dd className="flex text-base text-dark-500 cursor-pointer">
                        <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                        <span className="ml-3 text-gray-900">You can also reach us on <u>LinkedIn</u></span>
                      </dd></a>
                      <dt>
                        <span className="sr-only">Office</span>
                      </dt>
                      <dd className="flex text-base text-gray-900">
                        <OfficeBuildingIcon className="flex-shrink-0 w-6 h-6 text-dark-500" aria-hidden="true" />
                        <div>
                          <p className="ml-3 font-medium">Sara Fashionwear Ltd.</p>
                          <p className="ml-3 pb-1">Factory and knit division </p>
                          <p className="ml-3">Montribari Road, South Salna, Salna Bazar, Gazipur, 1703, Bangladesh</p>
                        </div>
                      </dd>
                      <dt>
                        <span className="sr-only">Factory</span>
                      </dt>
                      <dd className="flex text-base text-gray-900">
                        <OfficeBuildingIcon className="flex-shrink-0 w-6 h-6 text-dark-500" aria-hidden="true" />
                        <div>
                          <p className="ml-3 font-medium">Sara Fashionwear Ltd. Headquarters</p>
                          <p className="ml-3 font-medium pb-1">ABUCORP Apparel Sourcing</p>
                          <p className="ml-3">Plot 39, Road 7, Sector 4, 7th &amp; 8th floor, NANDAN MARS, Uttara, Dhaka</p>
                        </div>
                      </dd>
                      <dt>
                        <span className="sr-only">Office</span>
                      </dt>
                    </dl>
                  </div>
                  <ContactForm/>
                </div>
              </div>
            </div>
          </section>
          <div className="max-w-7xl mx-auto py-6 px-4 sm:py-12 sm:px-6 lg:px-8">
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default ContactPage
